export const environment = {
  production: false,
  authServiceWebsocketUrl: 'ws://localhost:8080/order-service/changes',
  //authServiceWebsocketUrl: 'ws://faas20nsf-int.bmwgroup.net/order-service/changes',
  productServiceWebsocketUrl: 'ws://localhost:8080/product-service/import',
  sso: {
    internal: {
      issuer: 'https://auth.bmwgroup.net:443/auth/oauth2/realms/root/realms/intranetb2x',
      clientId: '7e2359a8-8aeb-4183-b53b-ebff8ea66723'
    },
    external: {
      issuer: 'https://auth.bmwgroup.com:443/auth/oauth2/realms/root/realms/internetb2x',
      clientId: '893228b3-dcfd-484f-a4ce-18974e71b06b'
    },
    responseType: 'code',
    strictDiscoveryDocumentValidation: false,
    scope: 'openid profile email groups',
    redirectUri: window.location.origin,
    oidc: true
  },
  internalUrlPattern: 'faas-prod.eu'
};
