import { Component, Input,  Output, EventEmitter, OnInit, HostListener } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
import { GridApi, GridOptions, ICellRendererParams } from 'ag-grid-community';
import { TypeNumericEditorComponent } from "../../../numeric-editor/TypeNumericEditorComponent";
import { Plant } from 'src/app/components/order/model/order.model';
import {transferPrice} from "../../../service/transfer-price-constants";
import {calculateRoyalties, royaltiesProfitCalculator} from "../../../service/transfer-price-calculator";
import {calculateProfit} from "../../../service/transfer-price-calculator";
import {RoyaltiesIndicator} from "../../../model/operations.model";

@Component({
  selector: 'app-cost-allocations-grid',
  templateUrl: './cost-allocations-grid.component.html',
  styleUrls: ['./cost-allocations-grid.component.scss']
})
export class CostAllocationsGridComponent implements OnInit {
  rowSelection = 'single';
  @Input() rowData: any = [];
  @Input() whenOperationIsUnderEvaluation: boolean;
  @Input() selectedDetail: any[];
  @Input() map = new Map();
  @Input() hasCapturerRole: boolean;

  @Output() back = new EventEmitter();
  @Output() save = new EventEmitter();
  @Output() upload = new EventEmitter();
  showSideBar: boolean = false;
  selectedDetailShadow: any[];
  showGrid:boolean = true;
  new = false;
  selection: any;
  selectedRoyalties: any;
  gridApi: GridApi;
  gridColumnApi: any;
  filtering = false;
  runtimeCompilerData: any;
  gridColumns: any;
  domLayout: any;
  showNoData:boolean = false;
  totalPrice:number = 0;
  columns = [];
  showUnallocatedCosts: boolean = false;
  payloadObject = {};

  constructor(private translate: TranslateService) {
    this.translate.onLangChange.subscribe(() => {
      this.gridApi.refreshHeader();
      this.gridApi.refreshToolPanel();
    });
  }

  ngOnInit() {
    this.selectedRoyalties = this.selectedDetail[0].includeRoyalties;
    this.setColumns();
    this.gridColumns = this.columns;
    this.setGridOptions();

    if(!this.selectedDetail || this.selectedDetail.length === 0){
      this.new = true;
    } else {
      this.selection = this.selectedDetail[0];
      this.selectedDetailShadow = [...this.selectedDetail];
    }
  }

  onGridReady(params){
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.setHeaderHeight(this.isMobileViewport() ? 40 : 32);
    this.runtimeCompilerData.gridOptions.api?.addEventListener('filterChanged', (e) => {
      this.filtering = !this.filtering;
    });
    this.changeAllocation();
  }

  setColumns() {
    this.columns = [
      {
        field: 'group',
        headerName: this.translate.instant('group'),
        hide: true,
        headerValueGetter: this.localizeHeader.bind(this),
        sortable: true,
        filter: true,
        resize: true,
        rowGroup: true,
      }, {
        field: 'changeon',
        headerName: this.translate.instant('changeon'),
        headerValueGetter: this.localizeHeader.bind(this),
        children: [{
          field: 'techChange',
          headerName: this.translate.instant('techChange'),
          headerValueGetter: this.localizeHeader.bind(this),
          sortable: true,
          filter: true,
          resizable: true,
          resize: true,
          editable: true,
          enableValue: true,
          aggFunc: this.sumGroupTotals.bind(this),
          valueFormatter: this.moneyFormatter,
          valueGetter: royaltiesProfitCalculator.bind(this),
          cellEditor: 'typeNumericEditorComponent',
          cellEditorParams: {
            whenOperationIsUnderEvaluation: this.whenOperationIsUnderEvaluation,
            hasCapturerRole: this.hasCapturerRole,
            selectedDetail: this.selectedDetail,
            includeRoyalties: this.selectedRoyalties
          },
          type: 'rightAligned',
        }, {
          field: 'premises',
          headerName: this.translate.instant('premises'),
          headerValueGetter: this.localizeHeader.bind(this),
          sortable: true,
          filter: true,
          resize: true,
          editable: true,
          enableValue: true,
          aggFunc: this.sumGroupTotals,
          valueFormatter: this.moneyFormatter,
          valueGetter: royaltiesProfitCalculator.bind(this),
          cellEditor: 'typeNumericEditorComponent',
          cellEditorParams: {
            whenOperationIsUnderEvaluation: this.whenOperationIsUnderEvaluation,
            hasCapturerRole: this.hasCapturerRole,
            selectedDetail: this.selectedDetail,
            includeRoyalties: this.selectedRoyalties
          },
          type: 'rightAligned',
        }, {
          field: 'volumeChange',
          headerName: this.translate.instant('volumeChange'),
          headerValueGetter: this.localizeHeader.bind(this),
          sortable: true,
          filter: true,
          resize: true,
          editable: true,
          enableValue: true,
          aggFunc: this.sumGroupTotals,
          valueFormatter: this.moneyFormatter,
          valueGetter: royaltiesProfitCalculator.bind(this),
          cellEditor: 'typeNumericEditorComponent',
          cellEditorParams: {
            whenOperationIsUnderEvaluation: this.whenOperationIsUnderEvaluation,
            hasCapturerRole: this.hasCapturerRole,
            selectedDetail: this.selectedDetail,
            includeRoyalties: this.selectedRoyalties
          },
          type: 'rightAligned',
        }, {
          field: 'bmwOther',
          headerName: this.translate.instant('bmwOther'),
          headerValueGetter: this.localizeHeader.bind(this),
          sortable: true,
          filter: true,
          resize: true,
          editable: true,
          enableValue: true,
          aggFunc: this.sumGroupTotals,
          valueFormatter: this.moneyFormatter,
          valueGetter: royaltiesProfitCalculator.bind(this),
          cellEditor: 'typeNumericEditorComponent',
          cellEditorParams: {
            whenOperationIsUnderEvaluation: this.whenOperationIsUnderEvaluation,
            hasCapturerRole: this.hasCapturerRole,
            selectedDetail: this.selectedDetail,
            includeRoyalties: this.selectedRoyalties
          },
          type: 'rightAligned',
        }]
      }, {
        field: 'changesal',
        headerName: this.translate.instant('changesal'),
        headerValueGetter: this.localizeHeader.bind(this),
        children: [{
          field: 'fivePercent',
          headerName: this.translate.instant('fivePercent'),
          headerValueGetter: this.localizeHeader.bind(this),
          sortable: true,
          filter: true,
          resize: true,
          editable: true,
          enableValue: true,
          aggFunc: this.sumGroupTotals,
          valueFormatter: this.moneyFormatter,
          valueGetter: royaltiesProfitCalculator.bind(this),
          cellEditor: 'typeNumericEditorComponent',
          cellEditorParams: {
            whenOperationIsUnderEvaluation: this.whenOperationIsUnderEvaluation,
            hasCapturerRole: this.hasCapturerRole,
            selectedDetail: this.selectedDetail,
            includeRoyalties: this.selectedRoyalties
          },
          type: 'rightAligned',
        }, {
          field: 'effect',
          headerName: this.translate.instant('effect'),
          headerValueGetter: this.localizeHeader.bind(this),
          sortable: true,
          filter: true,
          resize: true,
          editable: true,
          enableValue: true,
          aggFunc: this.sumGroupTotals,
          valueFormatter: this.moneyFormatter,
          valueGetter: royaltiesProfitCalculator.bind(this),
          cellEditor: 'typeNumericEditorComponent',
          cellEditorParams: {
            whenOperationIsUnderEvaluation: this.whenOperationIsUnderEvaluation,
            hasCapturerRole: this.hasCapturerRole,
            selectedDetail: this.selectedDetail,
            includeRoyalties: this.selectedRoyalties
          },
          type: 'rightAligned',
        }, {
          field: 'salOther',
          headerName: this.translate.instant('salOther'),
          headerValueGetter: this.localizeHeader.bind(this),
          sortable: true,
          filter: true,
          resize: true,
          editable: true,
          enableValue: true,
          aggFunc: this.sumGroupTotals,
          valueFormatter: this.moneyFormatter,
          valueGetter: royaltiesProfitCalculator.bind(this),
          cellEditor: 'typeNumericEditorComponent',
          cellEditorParams: {
            whenOperationIsUnderEvaluation: this.whenOperationIsUnderEvaluation,
            hasCapturerRole: this.hasCapturerRole,
            selectedDetail: this.selectedDetail,
            includeRoyalties: this.selectedRoyalties
          },
          type: 'rightAligned',
        }]
      },
      {
        headerName: "categoryId",
        field: "categoryId",
        width: 20,
        hide: true,
        suppressToolPanel: true
      },
      {
        headerName: "priceId",
        field: "priceId",
        width: 20,
        hide: true,
        suppressToolPanel: true
      }
    ];
  }
  setGridOptions(){
    this.domLayout = 'autoHeight';
    this.runtimeCompilerData = {
      columns: this.gridColumns,
      gridOptions: <GridOptions>{
        getRowStyle: params => {
          return {order: params.node.rowIndex};
        },
        postSort: params => {
          setTimeout(() => {
            params.forEach(param => {
              param.updateData(param.data);
            });
          });
        },
        ensureDomOrder: true,
        suppressMovableColumns: false,
        rowDeselection: true,
        rowHeight: 32,
        headerHeight: 32,
        enableRangeSelection: true,
        enableRangeHandle: true,
        rowGroupPanelShow: 'onlyWhenGrouping',
        pagination: true,
        onCellValueChanged: (params) => {
          const result = this.instantiatePinnedRow();
          setTimeout(() => {
            params.api.forEachNode(row => {
              if (row.data) {
                this.calculatePinnedData(row.data, result);
              }
            });
            params.api.refreshClientSideRowModel('aggregate');
            this.calculateTotalTransferPrice(result);
            params.api.setPinnedTopRowData([result]);
          }, 0 );
        }
      },
      defaultColDef: {
        flex: 1,
        enableValue: true,
        cellClass: 'cell-wrap-text',
        enableRowGroup: true,
        enablePivot: true,
        sortable: true,
        autoHeight: true,
        resizable: true,
        filter: true,
      },
      sideBar: this.toggleSideBar(),
      statusBar: {
        statusPanels: [
          {
            statusPanel: 'agTotalAndFilteredRowCountComponent',
            align: 'left'
          },
          {
            statusPanel: 'agTotalRowCountComponent',
            align: 'center'
          },
          {statusPanel: 'agFilteredRowCountComponent'},
          {statusPanel: 'agSelectedRowCountComponent'},
          {statusPanel: 'agAggregationComponent'}
        ]
      },
      autoGroupColumnDef: {
        minWidth: 200,
        pinned: 'left'
      },
      autoGroupColumnDefAllocation: {
        field: 'category',
        headerName: 'Category',
        cellRenderer: 'agGroupCellRenderer',
        width: 200,
      },
      frameworkComponents:  {
        typeNumericEditorComponent: TypeNumericEditorComponent
      }
    };
  }

  cancelUnallocatedCosts() {
    this.showUnallocatedCosts = false;
  }

  saveWithDefaultValues() {
    this.cancelUnallocatedCosts();
    this.save.emit(this.payloadObject);
  }

  public changeAllocation() {
    this.selectedRoyalties = this.selection.includeRoyalties;
    this.setColumns();
    this.gridColumns = this.columns;
    this.setGridOptions();
    this.totalPrice = 0;
    if (this.map.get(this.selection.priceId)) {
      this.rowData = this.map.get(this.selection.priceId);
      this.gridApi.setPinnedTopRowData(this.createPinData(this.rowData));
    }
  }

  onBack() {
    this.back.emit();
  }

  onGetConfirmation() {
    this.changeAllocation();
    let outputRows:any[] = [];
    let totalRoyalties = 0;
    let calculatedProfit = { fivePercent: 0, volumeChange: 0, premises: 0, effect: 0, techChange: 0, salOther: 0, bmwOther: 0};
    this.gridApi.forEachNode(row => {
      if(row.data){
        if (row.data.category === transferPrice.category.profit) {
          for (let adjustmentReasonKey in transferPrice.adjustmentReason) {
              row.setDataValue(adjustmentReasonKey, this.gridApi.getValue(adjustmentReasonKey, row));
              calculatedProfit[adjustmentReasonKey] = +calculateProfit.call(this, adjustmentReasonKey, row.data.group);
          }
        } else if (row.data.category === transferPrice.category.royaltiesForTechnology) {
          for (let adjustmentReasonKey in transferPrice.adjustmentReason) {
            row.setDataValue(adjustmentReasonKey, this.gridApi.getValue(adjustmentReasonKey, row));
            totalRoyalties += +this.gridApi.getValue(adjustmentReasonKey, row);
          }
        }
        outputRows.push(row.data);
      }
    });

    let keys = [];
    const iteratorKeys = this.map.keys();
    let royaltiesMap = new Map();
    const royalties = RoyaltiesIndicator;
    for (let key of iteratorKeys) {
      keys.push(+key);
      this.selectedDetailShadow.forEach(selectedDetail => {
        if (selectedDetail.priceId === key) {
          royaltiesMap.set(key, Object.keys(royalties).filter(x => royalties[x] === selectedDetail.includeRoyalties)[0]);
        }
      });
    }
    this.payloadObject['ids'] = [...keys];
    this.payloadObject['rows'] = [...outputRows];
    this.payloadObject['royaltiesMap'] = royaltiesMap;
    this.payloadObject['price'] = Math.round((this.totalPrice + Number.EPSILON) * 100) / 100;
    this.payloadObject['totalRoyalties'] = Math.round((totalRoyalties + Number.EPSILON) * 100) / 100;
    this.payloadObject['calculatedProfit'] = calculatedProfit;
    this.payloadObject['selectedPriceId'] = this.selection.priceId;
    this.payloadObject["opsId"] = this.selectedDetailShadow[0].operationId;
    this.payloadObject["plant"] = Plant[window.sessionStorage.client];
    if (this.isAllocationCostsCaptured(outputRows)) {
      this.save.emit(this.payloadObject);
    } else {
      this.showUnallocatedCosts = true;
    }
  }

  isAllocationCostsCaptured(rows: any[]): boolean {
    let returnValue: boolean = false;
    rows.forEach(row => {
      if (row['bmwOther'] !== 0 || row['effect'] !== 0 || row['fivePercent'] !== 0 || row['premises'] !== 0 ||
        row['salOther'] !== 0 || row['techChange'] !== 0 || row['volumeChange'] !== 0) {
        returnValue = true;
      }
    });
    return returnValue;
  }

  onUpload() {
    let outputRows: any[] = [];
    this.gridApi.forEachNode(row => {
      if (row.data) {
        outputRows.push(row.data);
      }
    });
    this.upload.emit(outputRows);
  }

  buildPinnedTopRows(){
    this.gridApi.setPinnedTopRowData(this.createPinData(this.rowData));
  }

  localizeHeader(parameters: ICellRendererParams): string {
    let headerIdentifier = parameters.colDef.field;
    return this.translate.instant(headerIdentifier);
  }

  public moneyFormatter(params): string {
    let value;
    const formatter = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    if (params.value > 0 || params.value < 0) {
      value = formatter.format(params.value);
    } else {
      value = '';
    }
    return value;
  }

  sumGroupTotals(values) {
    let sum = 0;
      values.forEach((value) => {
          sum += +value;
      });
    return sum;
  }

  isMobileViewport(): boolean {
    const breakpoint =
      parseFloat(getComputedStyle(document.body).getPropertyValue('--component-breakpoint')) *
      parseFloat(getComputedStyle(document.documentElement).fontSize);
    const width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    return width < breakpoint;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event): void {
    this.gridApi.setHeaderHeight(this.isMobileViewport() ? 40 : 32);
    this.gridApi.resetRowHeights();
    this.gridApi.sizeColumnsToFit();
  }

  onFirstDataRendered(params): void {
    this.gridApi.expandAll();
    this.gridApi.setHeaderHeight(this.isMobileViewport() ? 40 : 32);
    this.gridApi.sizeColumnsToFit();
    this.gridApi.resetRowHeights();
    setTimeout(() => {
      this.gridApi.redrawRows();
      this.gridApi.sizeColumnsToFit();
    }, 0);
    this.changeAllocation();
  }

  onRefreshGridData($event: any) {
    if ( this.gridApi != undefined ) {
      this.gridApi.expandAll();
      this.gridApi.setHeaderHeight(this.isMobileViewport() ? 40 : 32);
      this.gridApi.sizeColumnsToFit();
      this.gridApi.resetRowHeights();
      setTimeout(() => {
        this.gridApi.redrawRows();
        this.gridApi.sizeColumnsToFit();
      }, 500);
    }
  }

  onModelUpdated(params): void {
    if (this.gridApi && this.gridApi.getDisplayedRowCount() == 0 && !this.filtering) {
      this.gridApi.showLoadingOverlay();
    }
    if (this.gridApi && this.gridApi.getDisplayedRowCount() > 0) {
      this.gridApi.hideOverlay();
    }

    setTimeout(() => {
      this.gridApi.redrawRows();
      this.gridApi.sizeColumnsToFit();
    }, 500);
  }

  toggleSideBar(): any {
    if (this.showSideBar) {
      return {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel'
          },
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel'
          }
        ],
        defaultToolPanel: undefined
      };
    }
    return null;
  }

  createPinData(rowData) {
    const result = this.instantiatePinnedRow();

    rowData.forEach((row) => {
      this.calculatePinnedData(row, result);
    });
    this.calculateTotalTransferPrice(result);
    return [result];
  }

  private calculateTotalTransferPrice(result: { fivePercent: number; volumeChange: number; premises: number; effect: number; techChange: number; category: string; salOther: number; bmwOther: number; group: string }) {
    const formatter = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    const total = Number(result.techChange) + Number(result.bmwOther) + Number(result.effect) + Number(result.fivePercent) +
      Number(result.premises) + Number(result.salOther) + Number(result.volumeChange);
    this.totalPrice = total;
    result.category = result.category + formatter.format(Number(total));
  }

  private calculatePinnedData(row, result: { fivePercent: number; volumeChange: number; premises: number; effect: number; techChange: number; category: string; salOther: number; bmwOther: number; group: string }) {
    if (row.category && row.category === transferPrice.category.profit && this.selection.includeRoyalties === RoyaltiesIndicator.ALWAYS) {
      for (let adjustmentReasonKey in transferPrice.adjustmentReason) {
        result[adjustmentReasonKey] += +calculateProfit.call(this, adjustmentReasonKey, row.group);
      }
    } else if (row.category && row.category === transferPrice.category.royaltiesForTechnology && this.selection.includeRoyalties === RoyaltiesIndicator.ALWAYS) {
      for (let adjustmentReasonKey in transferPrice.adjustmentReason) {
        result[adjustmentReasonKey] += +calculateRoyalties.call(this, adjustmentReasonKey, row.category, row.group);
      }
    } else {
      for (let adjustmentReasonKey in transferPrice.adjustmentReason) {
        result[adjustmentReasonKey] += +row[adjustmentReasonKey];
      }
    }
  }

  private instantiatePinnedRow() {
    const result = {
      category: 'Transfer Price Total: ',
      group: 'Totals',
      techChange: 0,
      bmwOther: 0,
      effect: 0,
      fivePercent: 0,
      premises: 0,
      salOther: 0,
      volumeChange: 0
    };
    return result;
  }
}
